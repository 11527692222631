.grid-connection-archive-inner-html {
  .headline-5 {
    margin-bottom: 16px;

    .light-weight {
      font-weight: 400;
    }
  }

  .headline-6 {
    color: var(--ene-color-primary-600);
    margin-top: 16px;
    margin-bottom: 8px;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--ene-color-secondary-400);
  }

  b {
    font-weight: 700;

    & > a {
      color: inherit;
    }
  }
}
