@import "./components/chart.scss";
@import "./components/footer.scss";
@import "./components/header.scss";
@import "./components/skeleton-loader.scss";

.panel-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.panel-container-lg {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  & > .subtitle-1 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: -14px;
  }

  & > .panel-container-section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    & > .subtitle-1 {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: -6px;
    }
  }
}

.panel-title {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #000099;
  color: var(--ene-color-primary-600);
  line-height: 1.5;
}

.panel-subtitle {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #000099;
  color: var(--ene-color-primary-600);
  line-height: 1.2;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.contentful {
  .rich-text {
    * {
      margin: revert;
      padding: revert;
      font-size: revert;
      font-style: revert;
      list-style-type: revert;
    }

    ul li::marker {
      color: #fe8f11;
    }
  }
}

.table-no-data {
  box-sizing: border-box;
  display: block;
  text-align: center;
  font-size: 12px;
  color: var(--ene-color-grey-600);
  padding: 16px;

  a {
    text-decoration: underline;
    color: var(--ene-color-secondary-500);
    cursor: pointer;
  }
}

.ene-icon:not(.has-dropdown).circle {
  color: var(--ene-color-SO);
  background: var(--ene-color-S);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;

  i {
    font-size: 0.8em;
  }
}

.introduction {
  .ene-tabs {
    .tabs__tab-bar {
      border-bottom: solid 1px var(--ene-color-O);
    }

    .tabs__tab {
      margin: 0 !important;
      padding-top: 1em !important;
      padding-bottom: 0 !important;
      color: var(--ene-color-NO) !important;

      &:first-child {
        border-top-left-radius: 2px;
      }

      &.active {
        span {
          border-bottom: solid 2px var(--ene-color-NNW);
          color: var(--ene-tabs-inactive-text) !important;
        }
      }

      span {
        display: block;
        height: 100%;
      }
    }

    .tabs__panel {
      padding: 1.75em 2em;
      border-bottom-left-radius: 2px !important;
      border-bottom-right-radius: 2px !important;
    }
  }

  .blue-card {
    .mat-checkbox-checkmark-path {
      stroke: var(--ene-color-primary-700) !important;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: transparent;
    }
  }
}

.ene-sidenav {
  .ene-sidepanel {
    &[name="dashboard"].ene-sidepanel--visible:not(.ene-sidepanel--collapse).vertical {
      background: var(--ene-color-white);
    }
  }

  .ene-sidepanel {
    &[name="timeline"].horizontal {
      overflow: visible !important;

      .content-full {
        height: 100%;

        > * {
          height: 100%;
        }
      }

      .ene-sidepanel-container,
      .toggle-collapsed {
        display: none;
      }
    }
  }
}

.ene-sidebar {
  .sidenav-item-dashboard {
    margin-bottom: 2rem;

    &.ene-sidebar-item {
      &:not(.collapse):not(.visible):hover {
        background-color: var(--ene-color-primary-600);
        color: var(--ene-color-white);
      }

      &:not(.collapse):not(.visible) {
        background-color: var(--ene-color-primary-100);
        color: var(--ene-color-primary-600);
      }

      &.visible:not(.collapse):not(.disabled)::before {
        content: none;
      }

      .ene-icon {
        font-size: 2em;
      }
    }
  }

  .sidenav-item-space {
    &-top {
      margin-top: 2rem;
    }

    &-bottom {
      margin-bottom: 2rem;
    }

    &-center {
      margin: 2rem 0;
    }
  }
}

ene-tabs.folder.horizontal {
  &.main-tabs,
  &.nested-tabs {
    > .tabs__tab-bar {
      background-color: var(--ene-color-SO);
      &:not(.disabled) > .tabs__tab {
        color: var(--ene-cockpit-switch-inactive-color);
        margin: 0;

        &.active {
          color: var(--ene-color-S);
        }
      }
    }

    ene-tab .tabs__panel {
      box-sizing: border-box;
      background: transparent;
      padding: 0;
    }
  }

  &.main-tabs {
    height: 100%;
    > .tabs__tab-bar {
      justify-content: space-between;
      border-bottom: 2px solid var(--ene-kpis-border-color);

      &:not(.disabled) > .tabs__tab {
        display: flex;

        &.active {
          border-bottom: 3px solid var(--ene-color-S);
        }

        &.disabled {
          background-color: white;
          color: var(--ene-color-grey-300);
        }
      }
    }
  }

  &.nested-tabs {
    > .tabs__tab-bar {
      padding: 0 10%;

      &:not(.disabled) > .tabs__tab {
        flex: unset;
      }
    }

    &.centered {
      > .tabs__tab-bar {
        justify-content: center;
      }
    }

    &.right {
      > .tabs__tab-bar {
        justify-content: flex-end;
      }
    }
  }
}

tpm-timeline-pentagon-indicator {
  sub {
    vertical-align: revert;
    font-size: revert;
  }
}
