@mixin typography-base($font-size: 1rem, $font-weight: 400, $color: var(--ene-color-grey-700)) {
  display: flex;
  align-items: center;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
  line-height: 1.2;
}

@mixin last-line($line-clamp: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-kpi {
  @include typography-base(4rem, 700, var(--ene-color-black));
}

.dashboard-body-1 {
  @include typography-base(3rem, 300, var(--ene-color-black));
}

.dashboard-body-2 {
  @include typography-base(2.25rem, 300, var(--ene-color-black));
}

.headline-1 {
  @include typography-base(2.25rem, 400, var(--ene-color-primary-600));
}

.headline-2 {
  @include typography-base(1.5rem, 500, var(--ene-color-primary-600));
}

.headline-3 {
  @include typography-base(1.25rem, 700, var(--ene-color-primary-600));
}

.headline-4 {
  @include typography-base(1.125rem, 700, var(--ene-color-primary-600));
}

.headline-5 {
  @include typography-base(0.875rem, 700, var(--ene-color-primary-600));
}

.headline-6 {
  @include typography-base(0.75rem, 700, var(--ene-color-grey-700));
}

.subtitle-1 {
  @include typography-base(1rem, 400, var(--ene-color-grey-700));
}

.subtitle-2 {
  @include typography-base(0.875rem, 400, var(--ene-color-grey-700));
}

.body-1 {
  @include typography-base(1.125rem, 400, var(--ene-color-grey-700));
}

.body-2 {
  @include typography-base(0.875rem, 400, var(--ene-color-grey-700));
}

.body-3 {
  @include typography-base(0.75rem, 400, var(--ene-color-grey-700));
}

.overline-1 {
  @include typography-base(0.875rem, 500, var(--ene-color-grey-700));
  text-transform: uppercase;
}

.overline-2 {
  @include typography-base(0.625rem, 500, var(--ene-color-grey-700));
  text-transform: uppercase;
}

.caption-1 {
  @include typography-base(0.625rem, 400, var(--ene-color-grey-700));
}

.caption-2 {
  @include typography-base(0.5rem, 400, var(--ene-color-grey-700));
}

.link-1 {
  @include typography-base(1.125rem, 400, var(--ene-color-secondary-500));
}

.last-line-1 {
  @include last-line(1);
}

.last-line-2 {
  @include last-line(2);
}

.last-line-3 {
  @include last-line(3);
}

.last-line-4 {
  @include last-line(4);
}
