/* You can add global styles to this file, and also import other style files */
@import "./themes/variables.scss";
@import "./themes/typography.scss";
@import "./enersis/core.scss";
@import "./material/core.scss";
@import "../app/features/overlays/global/blinking-one-page/blinking-one-page.styles.scss";
@import "./extensions/zenloop.css";

// old design scheme (should be depreceted soon)
:root {
  --ene-color-NW: #374a9a;
  --ene-color-NNW: #000099;
  --ene-color-WNW: #323751;
  --ene-color-W: #e3e3e3;
  --ene-color-WSW: #f2f2f2;
  --ene-color-SSW: #ee7700;
  --ene-color-S: #ff9900; // new, ugly orange :(
  --ene-color-SO: #ffffff;
  --ene-color-O: #cccccc;
  --ene-color-OSO: #f2f2f2;
  --ene-color-NO: #868686;
  --ene-color-NNO: #3b3b3b;
  --ene-color-N: #040b87; // new, ugly blue :(
  --ene-color-palette-warn: #de2828;
  --ene-color-palette-success: #a7dc6d;
  --ene-color-palette-info: #f0af45;
  --ene-color-background-warn: #db7676;
  --ene-color-background-success: #548e4e;
  --ene-color-background-info: #eacb99;
  --ene-color-shadow: rgba(38, 50, 56, 0.4);
  --ene-color-background-body: #ffffff;
  --ene-color-slide: #374a9a;
  --ene-color-stripped-table: #f6f6f6;
  --ene-color-detail-attribute: #0d3a40;
  --ene-color-person-background: #f8f8f8;
  --ene-contacts-margin: 30px;
  --ene-person-margin-top: 0;
  /*
    ui-helper

    ene-surface
    TODO: still need !important for overriding
  */
  --ene-surface-cta-background-color: linear-gradient(180deg, #ff9900 0%, #ee7700 100%) !important;
  --ene-surface-sidebar-background: #040b87 !important;
  --ene-surface-box-flat-background: var(--ene-color-SO) !important;
  --ene-toolbar-group-background-color: #787878;
  --ene-button-default-background-color: #4245aa !important;
  --ene-button-visible-background-default: var(--ene-color-S);
  --ene-button-visible-text: white;
  --ene-button-visible-text-hover: white;
  /*
    ui-layout

    ene-radio-button
  */
  --ene-radio-button-border-color-before: #bfbfbf;
  // ene-button
  --ene-button-default-background-hover: #061671;

  // ene-tabs TODO: need refactoring for component
  --ene-tabs-inactive-text: #263238;

  // ene-spinner, hardcode color cuz var(--ene-color-WNW) was rewritten
  --ene-spinner-three-background-color: #8aaddc;

  // tooltip
  --ene-tooltip-background-color-dark: #2b2b2b;
  --ene-tooltip-color-dark: #fff;

  // minimized guided tour
  --ene-tabs-inactive-text: var(--ene-color-NNW);

  // ene-kpis-v2
  --ene-kpis-color: #263238;
  --ene-kpis-border-color: #ededed;
  --ene-small-kpi-divider-color: #ededed;
  --ene-large-kpi-icon-color: #3b3b3b;

  --ene-tabs-inactive-background: white;
  --ene-color-links-default: #0000ee;
  --ene-color-links-visited: #551a8b;
  --ene-cockpit-switch-inactive-color: #263238;
  --ene-h2-color: #1900a7;

  /* ene-range-slider */
  --ene-range-slider-connect: var(--ene-color-S);
}

body,
html {
  height: 100%;
}

body {
  font-family: var(--ene-font-family-primary);
  overflow: hidden;
  // prevent overscroll / bouncing on iOS
  position: fixed;
  // needed because of position:fixed
  width: 100%;
  margin: 0;
}

h2 {
  text-transform: none;
}

.custom-legend {
  $widthForOneliner: 1230px;
  padding: 0.4em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .legend-item-group {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 0 0 1px #cfd8dc; // inline shadow needed to create one line with .legend-item-title
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-right: 0.75em;
    }
  }

  .legend-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .consumption span,
  .production span {
    display: inline-block;
    width: 1.5em;
    height: 2px;
    vertical-align: middle;
  }

  // dashed line
  .interpolated span {
    display: inline-block;
    vertical-align: middle;
    border-top: 2px dashed #979797;
    width: 1em;
    height: 1px;
    font-size: 1.2em;
  }

  .production span {
    background-color: var(--ene-color-N);
  }

  .consumption {
    border: 1px solid #979797;
    border-radius: 4px;
  }

  .consumption span {
    background-color: var(--ene-color-S);
  }

  .feedin,
  .on-site-power,
  .remain-power {
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
    vertical-align: middle;
    border-radius: 4px;
    margin: 0 0.4em;
  }

  .feedin {
    background-color: #b2cef4;
  }

  .on-site-power {
    background-color: #b39fa5;
  }

  .remain-power {
    background-color: var(--ene-color-S);
    opacity: 0.3;
  }

  .legend-item,
  .legend-item-title {
    line-height: 1.8em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; // align content vertically centered
    display: flex;
    align-items: center;

    &.checkable {
      cursor: pointer;
    }

    .ene-radio-button {
      padding-left: 1.5em;
    }

    input[type="radio"] {
      // center it perfectly
      & + .ene-radio-button:before {
        top: 50%;
        transform: translateY(-50%);
      }

      &:checked + .ene-radio-button:before {
        $ene-radio-botton-background-checked: #374a9a;
        $ene-radio-button-box-shadow-color-checked: #fff;
        background-color: var(--ene-color-S); // $ene-radio-botton-background-checked; // $S
        box-shadow: inset 0 0 0 4px var(--ene-color-SO); // $SO $ene-radio-button-box-shadow-color-checked
      }

      &:focus + .ene-radio-button:before {
        outline: none;
        border-color: #374a9a;
      }

      &:not(:checked):not(:disabled) {
        + .ene-radio-button:hover::before {
          box-shadow: 0px 0px 0px 0.33em fade(#374a9a, 60%);
        }
      }
    }
  }

  .legend-item-title {
    $base: 1em;
    $legend-item-arrow-outer: $base;
    $legend-item-arrow-inner: calc(#{$base} - 1px);
    $legend-item-arrow-inner-minus: calc(-#{$base} + 1px);
    height: 100%;
    margin-left: 0px;
    border: 1px solid #979797;
    border-radius: 4px 0px 0px 4px;
    margin-right: 1em;
    min-width: 7.5em;

    &:after,
    &:before {
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    @mixin arrow($base, $addition) {
      border-width: calc(#{$base} - #{$addition});
      margin-top: calc(-#{$base} + #{$addition});
    }

    &:after {
      left: 100%;
      border-color: rgba(136, 183, 213, 0);
      @include arrow(1em, 1px);
    }

    &:before {
      left: calc(100% + 1px);
      border-color: rgba(194, 225, 245, 0);
      border-left-color: #979797;
      @include arrow(1em, 0px);
    }
  }
}

.last-publish {
  background: var(--ene-color-grey-50);
  padding: 0.5rem;
  color: var(--ene-color-grey-600);
  margin-top: 2rem;
}

.html-translate {
  color: var(--ene-color-grey-700);
  font-size: 1rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--ene-color-primary-600);
    margin-bottom: 1.5rem;
  }

  h3,
  h4 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--ene-color-primary-600);
    margin: 0;
  }

  ul,
  p,
  section,
  .card {
    margin-bottom: 1rem;
  }

  a.bold,
  p.bold {
    font-weight: 700;
    color: var(--ene-color-grey-700);
  }

  a.default {
    color: inherit;
  }

  > p:last-child,
  > section:last-child {
    margin-bottom: 0;
  }

  section {
    background-color: var(--ene-color-grey-50);
    color: var(--ene-color-grey-600);
    border-radius: 2px;
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  a {
    text-decoration: underline;
    color: var(--ene-color-secondary-500);
    cursor: pointer;
  }

  ul {
    list-style: disc inside;
    list-style-position: outside;
    margin-left: 1rem;

    &.no-bullet {
      list-style-type: none;
    }

    p {
      margin-bottom: initial;
    }
  }

  ul[type="decimal"] {
    list-style-type: decimal;
  }

  span {
    font-weight: 600;
    color: var(--html-span-color, --ene-color-light-blue-500);
  }

  div.card {
    border: 1px solid var(--ene-color-grey-700);

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.warning-block {
  background-color: var(--ene-color-secondary-100);
  padding: 1rem;
}

a.default {
  color: inherit;
}
