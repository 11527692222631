.mat-checkbox {
  display: block !important;

  &.mat-checkbox-checked {
    &.cookies-type__functional {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          background-color: var(--ene-color-white);
        }
      }

      .mat-checkbox-checkmark-path {
        stroke: var(--ene-color-green-300) !important;
      }
    }

    .mat-checkbox-inner-container {
      .mat-checkbox-background {
        background-color: var(--ene-color-secondary-400);
      }
    }
  }

  &-disabled.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: var(--ene-color-grey-200) !important;
    }
  }

  &-indeterminate:not(.mat-checkbox-disabled) .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--ene-color-secondary-400) !important;
  }
}
