$dialog-base-spacing: 1.5rem;
$dialog-big-font-size: 1.5rem;

.cdk-overlay-pane {
  max-height: -webkit-fill-available !important;

  .no-padding .mat-dialog-container {
    padding: 0;
  }

  &.dark .mat-dialog-container {
    background-color: var(--ene-color-grey-700);
    color: var(--ene-color-white);

    .mat-dialog-actions {
      border-top: none;
    }
  }

  .mat-dialog-container {
    border-radius: 0;
    padding: 0;

    .mat-dialog-header {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: var(--ene-color-grey-100);
      font-size: $dialog-big-font-size;
      padding: $dialog-base-spacing;

      .mat-dialog-title {
        overflow: hidden;
        white-space: initial;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .mat-icon {
          // headline-3
          font-size: 1.5rem;
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 2rem;
        }

        h1 {
          font-weight: 500;
          @extend .headline-3;
        }
      }

      .mat-icon.ec_building_delete_address {
        color: var(--ene-color-grey-300);
      }
    }

    .mat-dialog-content {
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: $dialog-base-spacing;
      margin: 0;
    }

    .mat-dialog-actions {
      justify-content: space-between;
      min-height: max-content;
      border-top: 1px solid var(--ene-color-grey-200);
      padding: $dialog-base-spacing;
      margin: 0;
    }
  }
}

.configuration-area-backdrop {
  background: linear-gradient(119.38deg, var(--ene-color-primary-600) -22.27%, var(--ene-color-primary-700) 127.38%);
}

.measure-editor-dialog button[mat-dialog-close] {
  cursor: pointer;
  background: none;
  border: 0;
  outline: none !important;
  padding: 0;
  display: flex;
  color: var(--ene-color-grey-700);
  font-size: 1rem;
}

.changelog-process-dialog {
  .mat-icon.title-icon {
    font-size: 0.7rem !important;
  }
}
