.mat-icon.notranslate {
  position: relative;
  color: var(--ene-color-secondary-500);
  width: 1em;
  height: 1em;
  font-size: 1em;
  overflow: initial;

  &.circle {
    overflow: hidden;
    color: var(--ene-color-white);
    background-color: var(--ene-color-secondary-500);
    border-radius: 50%;

    &::before {
      font-size: 0.8rem;
    }
  }

  & > svg,
  &::before {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    margin: 0;
  }
}

.navbar-button,
.ene-list-item,
.ene-button-base,
.mat-button-wrapper {
  & > .mat-icon.notranslate {
    color: inherit;
  }
}
