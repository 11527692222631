$ene-header-value: 1.5625;
$ene-header-default-font-size: 1.2rem;

app-header {
  z-index: 2;
}

.ene-header {
  display: block;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(0, 0, 0, 0.15);

  [data-section="left"] {
    border-left: 1px solid var(--ene-color-grey-500);
    padding-left: 10px;

    .headline-5,
    .body-1 {
      color: var(--ene-color-grey-700);
    }
  }

  [data-section="center"] {
    display: flex;
    align-items: center;
  }

  [data-section="right"] {
    height: 50%;
  }

  .ene-button.accent.ene-default-button.multi-regions {
    &:not(:hover) {
      color: var(--ene-color-grey-500);
    }

    &:hover {
      color: var(--ene-color-grey-500);
      background: transparent;
    }
  }
}

.ene-header.big {
  .ene-header-row {
    &.visible {
      @include adaptiveSizeMixIn(false, $ene-header-value, false);
    }

    &:not(.ene-header-secondary) ul.ene-header-right > li {
      @include adaptiveSizeMixIn($ene-header-value, $ene-header-value, $ene-header-value);
    }

    .ene-header-left,
    .ene-header-center {
      padding: 0 1em;

      .platform {
        display: flex;
        flex-direction: column;

        &-title {
          font-weight: 600;
        }

        span {
          line-height: initial;
        }
      }

      div {
        color: var(--ene-color-grey-700);
        font-size: $ene-header-default-font-size;
      }
    }

    .ene-header-left img {
      height: 50%;
    }

    .ene-header-center {
      .ene-icon {
        font-size: 1.3rem;
        margin-left: 0.5rem;
        color: var(--ene-color-black);
        cursor: pointer;
      }

      img {
        height: 56%;
      }
    }

    .ene-header-left .platform {
      border-left: 1px solid var(--ene-color-grey-200);
      line-height: 2;
      margin-left: 1em;
      padding: 0.5rem 0 0.5rem 1rem;
    }

    .ene-header-center .municipality-name {
      margin-left: 0.65em;
    }

    ul.ene-header-right li {
      display: flex;
      border-left-width: 0 !important;

      > * {
        flex: 1;
      }

      &.launchpad {
        cursor: initial;

        &:hover {
          background-color: unset;
        }
      }

      .splitter {
        background-color: transparent;
        width: 100%;
        max-width: 1px;
        border-left: 1px solid var(--ene-color-grey-200);
      }
    }
  }
}
