$base-snack-bar-spacing: 1rem;
$base-snack-bar-sizing: 1rem;
$base-snack-bar-font: 1rem;

$base-snack-bar-width: 330px;

.mat-snack-bar-container {
  &.ene-snack-bar {
    background-color: var(--ene-color-pink-300);
    min-height: min-content;
    max-width: $base-snack-bar-width;
    min-width: $base-snack-bar-width;
    margin: $base-snack-bar-spacing;
    padding: $base-snack-bar-spacing;

    &.info {
      background-color: var(--ene-color-grey-700);

      .mat-simple-snackbar-action {
        button.mat-button.small {
          color: var(--ene-color-secondary-300);
        }
      }
    }

    &.error {
      background-color: var(--ene-color-error-300);
    }

    &.success {
      background-color: var(--ene-color-success-300);
    }

    button.mat-icon-button.small {
      background: transparent;
      color: var(--ene-color-white);
    }
  }

  &.nowrap {
    max-width: max-content;

    .mat-simple-snackbar {
      align-items: center;
    }
  }
}

.mat-simple-snackbar {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1rem;
  color: var(--ene-color-white);
  opacity: 1;
  font-size: $base-snack-bar-font;
  line-height: 1.1;
}
