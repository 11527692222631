:root {
  --ene-color-primary-700: #061671;
  --ene-color-primary-600: #000099;
  --ene-color-primary-500: #374a9a;
  --ene-color-primary-400: #848fcf;
  --ene-color-primary-300: #8aaddc;
  --ene-color-primary-200: #b5def2;
  --ene-color-primary-100: #c4d6ed;
  --ene-color-primary-50: #d8e3f3;

  --ene-color-secondary-700: #8a4500;
  --ene-color-secondary-600: #bd5e00;
  --ene-color-secondary-500: #ee7700;
  --ene-color-secondary-400: #ff9900;
  --ene-color-secondary-300: #ffad33;
  --ene-color-secondary-200: #ffc266;
  --ene-color-secondary-100: #ffd699;
  --ene-color-secondary-50: #ffebcc;

  --ene-color-grey-800: #212121;
  --ene-color-grey-700: #3b3b3b;
  --ene-color-grey-600: #545454;
  --ene-color-grey-500: #828282;
  --ene-color-grey-400: #969696;
  --ene-color-grey-300: #b1b1b1;
  --ene-color-grey-200: #cccccc;
  --ene-color-grey-100: #e3e3e3;
  --ene-color-grey-50: #f2f2f2;
  --ene-color-grey-25: #fafafa;

  --ene-color-white: #ffffff;
  --ene-color-black: #000000;

  --ene-gradient-primary-1: linear-gradient(116.42deg, rgba(0, 0, 153, 0.0001) 9.41%, rgba(6, 22, 113, 0.98) 100%);
  --ene-gradient-primary-2: linear-gradient(
    102.4deg,
    var(--ene-color-primary-600) -22.27%,
    var(--ene-color-primary-700) 127.38%
  );

  --ene-gradient-secondary-1: linear-gradient(
    102.4deg,
    var(--ene-color-secondary-400) -22.27%,
    var(--ene-color-secondary-500) 127.38%
  );

  --ene-gradient-secondary-2: linear-gradient(
    180deg,
    var(--ene-color-secondary-400) 0%,
    var(--ene-color-secondary-500) 100%
  );

  --ene-color-error-600: #c73a34;
  --ene-color-error-500: #862525;
  --ene-color-error-300: #b50015;
  --ene-color-error-200: #b73737;
  --ene-color-error-100: #e2001a;
  --ene-color-error-50: #ee6676;

  --ene-color-success-600: #068067;
  --ene-color-success-500: #045343;
  --ene-color-success-400: #29a28a;
  --ene-color-success-300: #06856c;
  --ene-color-success-100: #08b593;
  --ene-color-success-50: #76d8b3;
  --ene-color-success-25: #afe9d3;
  --ene-color-success-10: #d6f5e9;
}

// Data visualization colors
:root {
  --ene-color-yellow-700: #958300;
  --ene-color-yellow-600: #b29d00;
  --ene-color-yellow-500: #ccb300;
  --ene-color-yellow-400: #e5ca00;
  --ene-color-yellow-300: #ffe000;
  --ene-color-yellow-200: #ffe93f;
  --ene-color-yellow-100: #fff27e;
  --ene-color-yellow-50: #fff6b3;

  --ene-color-sand-700: #6f5000;
  --ene-color-sand-600: #a57409;
  --ene-color-sand-500: #d5960b;
  --ene-color-sand-400: #f3b120;
  --ene-color-sand-300: #f6c251;
  --ene-color-sand-200: #f8d381;
  --ene-color-sand-100: #fbe4b1;
  --ene-color-sand-50: #fdf1d8;

  --ene-color-green-700: #34430a;
  --ene-color-green-600: #4f660f;
  --ene-color-green-500: #719315;
  --ene-color-green-400: #94c11c;
  --ene-color-green-300: #abdf20;
  --ene-color-green-200: #c1e75a;
  --ene-color-green-100: #d1ed87;
  --ene-color-green-50: #e4f4b8;
  --ene-color-green-25: #f2fadc;

  --ene-color-emerald-700: #0f4324;
  --ene-color-emerald-600: #176738;
  --ene-color-emerald-500: #168144;
  --ene-color-emerald-400: #149a4f;
  --ene-color-emerald-300: #1ac766;
  --ene-color-emerald-200: #2fe47f;
  --ene-color-emerald-100: #78edab;
  --ene-color-emerald-50: #aef4cd;
  --ene-color-emerald-A500: #0eaa0e;

  --ene-color-dashboard-resolved: #6dab2f;

  --ene-color-teal-700: #228a97;
  --ene-color-teal-600: #005b66;
  --ene-color-teal-500: #007585;
  --ene-color-teal-400: #4fbbcc;
  --ene-color-teal-300: #37afbf;
  --ene-color-teal-200: #80d1dc;
  --ene-color-teal-100: #c9f3f8;
  --ene-color-teal-50: #e4f9fb;

  --ene-color-light-blue-700: #114864;
  --ene-color-light-blue-600: #196790;
  --ene-color-light-blue-500: #2086bb;
  --ene-color-light-blue-400: #33a2db;
  --ene-color-light-blue-300: #5eb6e3;
  --ene-color-light-blue-200: #8acaeb;
  --ene-color-light-blue-100: #b5def2;
  --ene-color-light-blue-50: #e1f1fa;

  --ene-color-blue-700: #030c3f;
  --ene-color-blue-600: #06166f;
  --ene-color-blue-500: #081fa0;
  --ene-color-blue-400: #0b29d0;
  --ene-color-blue-300: #1f72e8;
  --ene-color-blue-200: #33bbff;
  --ene-color-blue-100: #70cfff;
  --ene-color-blue-50: #ade4ff;

  --ene-color-purple-700: #3d0a38;
  --ene-color-purple-600: #4a1d51;
  --ene-color-purple-500: #572f6a;
  --ene-color-purple-400: #744b89;
  --ene-color-purple-300: #9066a7;
  --ene-color-purple-200: #b194c1;
  --ene-color-purple-100: #d3c2dc;
  --ene-color-purple-50: #e8dfec;

  --ene-color-pink-700: #7f106f;
  --ene-color-pink-600: #b5179f;
  --ene-color-pink-500: #da1bbe;
  --ene-color-pink-400: #e741cf;
  --ene-color-pink-300: #ed6edb;
  --ene-color-pink-200: #f29be6;
  --ene-color-pink-100: #f8c7f1;
  --ene-color-pink-50: #fee8fb;

  --ene-color-crimson-700: #431414;
  --ene-color-crimson-600: #571a1a;
  --ene-color-crimson-500: #6a2020;
  --ene-color-crimson-400: #932c2c;
  --ene-color-crimson-300: #b83737;
  --ene-color-crimson-200: #cc5656;
  --ene-color-crimson-100: #d98282;
  --ene-color-crimson-50: #e7b1b1;

  --ene-color-coral-700: #491704;
  --ene-color-coral-600: #772709;
  --ene-color-coral-500: #ab380d;
  --ene-color-coral-400: #e64b11;
  --ene-color-coral-300: #f06a38;
  --ene-color-coral-200: #f48d67;
  --ene-color-coral-100: #f48d67;
  --ene-color-coral-50: #fbd4c6;

  --ene-color-brown-700: #402f21;
  --ene-color-brown-600: #624732;
  --ene-color-brown-500: #825f43;
  --ene-color-brown-400: #a57855;
  --ene-color-brown-300: #b89375;
  --ene-color-brown-200: #cbb09a;
  --ene-color-brown-100: #dccabc;
  --ene-color-brown-50: #eee5dd;

  // gp-components
  --gp-color-grey-10: #fbfbfb;
  --gp-color-grey-100: #e0e0e0;
  --gp-color-grey-1000: #212121;
  --gp-color-grey-200: #cccccc;
  --gp-color-grey-25: #f6f6f6;
  --gp-color-grey-300: #b7b7b7;
  --gp-color-grey-400: #a1a1a1;
  --gp-color-grey-50: #ededed;
  --gp-color-grey-500: #8c8c8c;
  --gp-color-grey-600: #777777;
  --gp-color-grey-700: #616161;
  --gp-color-grey-800: #4c4c4c;
  --gp-color-grey-900: #363636;
  --gp-color-primary-10: #f2f7fa;
  --gp-color-primary-100: #bcc7e7;
  --gp-color-primary-1000: #030b39;
  --gp-color-primary-200: #a0abdb;
  --gp-color-primary-25: #e5edf7;
  --gp-color-primary-300: #848fcf;
  --gp-color-primary-400: #5e6db5;
  --gp-color-primary-50: #d8e3f3;
  --gp-color-primary-500: #374a9a;
  --gp-color-primary-600: #1f3086;
  --gp-color-primary-700: #061671;
  --gp-color-primary-800: #05125e;
  --gp-color-primary-900: #040f4c;
  --gp-color-primary-A300: var(--gp-color-primary-300);
  --gp-color-primary-A500: var(--gp-color-primary-500);
  --gp-color-primary-A700: var(--gp-color-primary-700);
  --gp-color-primary-gradient-1: linear-gradient(119.38deg, #000099 -22.27%, var(--gp-color-primary-700) 127.38%);
  --gp-color-primary-gradient-2: linear-gradient(141.82deg, rgba(0, 0, 153, 0.0001) 9.41%, rgba(6, 22, 113, 0.98) 100%);
  --gp-color-secondary-10: #fef9f0;
  --gp-color-secondary-100: #fde1b7;
  --gp-color-secondary-1000: #852e00;
  --gp-color-secondary-200: #fdd79f;
  --gp-color-secondary-25: #fef4e4;
  --gp-color-secondary-300: #fcc97e;
  --gp-color-secondary-400: #f7ae54;
  --gp-color-secondary-50: #fdebcf;
  --gp-color-secondary-500: #f3922a;
  --gp-color-secondary-600: #ee7700;
  --gp-color-secondary-700: #e66200;
  --gp-color-secondary-800: #de4d00;
  --gp-color-secondary-900: #b94000;
  --gp-color-secondary-A300: var(--gp-color-secondary-300);
  --gp-color-secondary-A500: var(--gp-color-secondary-500);
  --gp-color-secondary-A700: var(--gp-color-secondary-700);
  --gp-color-secondary-gradient-1: linear-gradient(180deg, #ff9900 0%, var(--gp-color-secondary-600) 100%);
  --gp-color-white: #ffffff;
  --gp-color-white-dimmed: #ffffff40;
  --gp-elevation-3d-10: 0px 0px 6px rgba(28, 161, 219, 0.24), 0px 2px 5px rgba(49, 43, 101, 0.1),
    0px 1px 2px rgba(49, 43, 101, 0.18), inset -2px -2px 8px rgba(28, 47, 142, 0.1);
  --gp-elevation-3d-20: 0px 7px 8px rgba(49, 43, 101, 0.12), 0px 12px 17px rgba(28, 161, 219, 0.12),
    0px 5px 24px rgba(28, 161, 219, 0.16), inset -2px -2px 16px rgba(28, 47, 142, 0.1);
  --gp-elevation-3d-30: 0px 11px 15px rgba(49, 43, 101, 0.24), 0px 18px 38px rgba(28, 161, 219, 0.18),
    0px 9px 46px rgba(28, 161, 219, 0.12), inset -4px -4px 24px rgba(28, 47, 142, 0.08);
  --gp-elevation-basic-10: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.12);
  --gp-elevation-basic-20: 0px 7px 8px rgba(0, 0, 0, 0.1), 0px 12px 17px rgba(0, 0, 0, 0.1),
    0px 5px 24px rgba(0, 0, 0, 0.12);
  --gp-elevation-basic-30: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 18px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.1);
  --gp-elevation-light-10: 0px 0px 8px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.025),
    0px 1px 4px rgba(0, 0, 0, 0.03);
  --gp-elevation-light-20: 0px 2px 8px rgba(0, 0, 0, 0.03), 0px 7px 16px rgba(0, 0, 0, 0.025),
    0px 12px 34px rgba(0, 0, 0, 0.025), 0px 5px 48px rgba(0, 0, 0, 0.03);
  --gp-elevation-light-30: 0px 2px 18px rgba(0, 0, 0, 0.05), 0px 11px 30px rgba(0, 0, 0, 0.04),
    0px 18px 76px rgba(0, 0, 0, 0.035), 0px 9px 92px rgba(0, 0, 0, 0.025);
  --gp-elevation-tinted-10: 0px 0px 6px rgba(28, 161, 219, 0.24), 0px 2px 5px rgba(49, 43, 101, 0.1),
    0px 1px 2px rgba(49, 43, 101, 0.18);
  --gp-elevation-tinted-20: 0px 7px 8px rgba(49, 43, 101, 0.12), 0px 12px 17px rgba(28, 161, 219, 0.12),
    0px 5px 24px rgba(28, 161, 219, 0.16);
  --gp-elevation-tinted-30: 0px 11px 15px rgba(49, 43, 101, 0.24), 0px 18px 38px rgba(28, 161, 219, 0.18),
    0px 9px 46px rgba(28, 161, 219, 0.12);
  --gp-font-family-primary: var(--ene-font-family-primary);
  --gp-font-family-secondary: "Mark OT", var(--gp-font-family-primary);

  --gp-glow-primary: 0px 0px 4px var(--gp-color-primary-500);
  --gp-glow-secondary: 0px 0px 4px var(--gp-color-secondary-500);
  --gp-select-text-color: var(--gp-color-grey-700);
  --gp-select-theme: var(--gp-color-grey-700);
  --gp-tabs-theme: var(--gp-color-secondary-500);
  --gp-toggle-button-theme: var(--gp-color-secondary-500);
  --gp-tooltip-dark-theme: var(--gp-color-grey-900);
  --gp-icon-color-grey: var(--ene-color-grey-600);
  --gp-switch-color-secondary: var(--gp-color-secondary-500);
  --gp-switch-color-primary: var(--gp-color-primary-500);

  // markdown
  --gp-prose-h3-color: var(--ene-color-primary-600);
  --gp-prose-text-color: var(--ene-color-grey-700);
  --gp-prose-link-color: var(--ene-color-secondary-500);

  // button
  --gp-button-color-primary-basic: var(--ene-color-secondary-500);
  --gp-button-color-primary-basic-hover: var(--ene-color-secondary-50);
}
