@use "@angular/material" as mat;

$base-expansion-font: 1rem;
$base-expansion-spacing: 1rem;
$base-expansion-border-radius: 2px;

$expansion-elevation-unit: 2;

.mat-accordion.custom {
  .mat-expansion-panel {
    border-radius: $base-expansion-border-radius;
    box-shadow: none;

    /** Borders overrides */
    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
      border-radius: $base-expansion-border-radius;
    }

    &:first-of-type {
      border-top-right-radius: $base-expansion-border-radius;
      border-top-left-radius: $base-expansion-border-radius;
    }

    &:last-of-type {
      border-bottom-right-radius: $base-expansion-border-radius;
      border-bottom-left-radius: $base-expansion-border-radius;
    }

    &:not(.mat-expansion-panel-spacing):not(:last-child) {
      margin-bottom: $base-expansion-spacing;
    }

    &.mat-expansion-panel-spacing {
      &:not(:first-child) {
        margin: $base-expansion-spacing 0;
      }

      &.shadow {
        @include mat.elevation($expansion-elevation-unit);
      }
    }

    & .mat-expansion-panel-header.cdk-keyboard-focused,
    & .mat-expansion-panel-header.cdk-program-focused,
    &:not(.mat-expanded) .mat-expansion-panel-header:hover {
      &:not([aria-disabled="true"]) {
        background: inherit;
      }
    }

    .mat-expansion-panel-body {
      font-size: $base-expansion-font;
      padding: 0 $base-expansion-spacing;
    }
  }

  &.spaced {
    display: block;
    background: var(--ene-color-white);
    padding: 1rem;

    .mat-expansion-panel {
      border: 1px solid var(--ene-color-grey-50);
    }

    & .mat-expansion-panel-header.cdk-keyboard-focused,
    & .mat-expansion-panel-header.cdk-program-focused,
    &:not(.mat-expanded) .mat-expansion-panel-header:hover {
      &:not([aria-disabled="true"]) {
        background: var(--ene-color-grey-50);
      }
    }
  }

  &.non-spaced {
    .mat-expansion-panel {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .mat-expansion-panel-header {
    padding: 0 $base-expansion-spacing;

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      align-items: center;
      font-weight: 700;
    }

    .mat-expansion-panel-header-title {
      color: var(--ene-color-primary-600);
      font-size: 1.125 * $base-expansion-font;
      line-height: 1.5;

      img {
        height: 1em;
        width: auto;
        margin-right: 0.5 * $base-expansion-spacing;
      }
    }

    .mat-expansion-panel-header-description {
      justify-content: flex-end;
      color: var(--ene-color-green-300);
      font-size: $base-expansion-font;
    }

    .mat-expansion-indicator {
      &::after {
        display: block;
        color: var(--ene-color-grey-700);
        padding: 5px;
        margin-top: -7px;
      }
    }
  }
}
