.accounting-archive-inner-html {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--ene-color-primary-600);

  .light {
    font-weight: 400;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--ene-color-primary-600);
  }
}
