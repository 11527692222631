.blinking-one-page-loader-container {
  position: absolute;
  height: 100px;
  width: 100px;
  margin: auto;
  transform-origin: center center;
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}

.blinking-one-page-svg-loader {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: dash 1.5s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
}

.blinking-one-page-svg-circle,
.blinking-one-page-svg-square {
  fill: transparent;
  stroke-width: 4;
  stroke: var(--ene-color-green-400);

  &--selectable {
    cursor: pointer;
    stroke: var(--ene-color-secondary-400);
    animation: strokeOpacity 2s infinite;
  }
}

.blinking-one-page-svg-circle-image-group,
.blinking-one-page-svg-square-image-group {
  &--selectable {
    cursor: pointer;
  }
}

.blinking-one-page-svg-circle-image,
.blinking-one-page-svg-square-image {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.blinking-one-page-svg-square-image {
  background-color: var(--ene-color-white);
}

.blinking-one-page-container .blinking-one-page-tooltip {
  padding: 10px;

  &[class*="blinking-one-page-tooltip--left"],
  &[class*="blinking-one-page-tooltip--right"] {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &[class*="blinking-one-page-tooltip--top"],
  &[class*="blinking-one-page-tooltip--bottom"] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--top-left {
    align-items: flex-start !important;
  }

  &--top-right {
    align-items: flex-end !important;
  }

  &--bottom-left {
    align-items: flex-start !important;
  }

  &--bottom-right {
    align-items: flex-end !important;
  }

  &--left .blinking-one-page-tooltip__arrow,
  &--top .blinking-one-page-tooltip__arrow,
  &--top-left .blinking-one-page-tooltip__arrow,
  &--top-right .blinking-one-page-tooltip__arrow {
    order: 2;
  }

  &--left .blinking-one-page-tooltip__arrow {
    transform: rotate(180deg);
  }

  &--bottom .blinking-one-page-tooltip__arrow,
  &--bottom-left .blinking-one-page-tooltip__arrow,
  &--bottom-right .blinking-one-page-tooltip__arrow {
    transform: rotate(90deg);
  }

  &--top .blinking-one-page-tooltip__arrow,
  &--top-left .blinking-one-page-tooltip__arrow,
  &--top-right .blinking-one-page-tooltip__arrow {
    transform: rotate(-90deg);
  }

  &__box {
    box-sizing: border-box;
    background-color: rgba(33, 33, 33, 0.8);
    color: white;
    line-height: 1.5;
    border-radius: 8px;
    padding: 16px;
    flex: 1;

    h1 {
      font-size: 2rem;
      color: inherit;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  &__title {
    text-align: left;
    font-weight: 700;
  }

  &__arrow {
    background-image: url(/assets/images/bop/vector.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    height: 29px;
    width: 25px;
    margin: 5px;
  }

  &--arrow {
    padding: 0;
  }
}

@keyframes strokeOpacity {
  from {
    stroke-opacity: 0;
  }

  50% {
    stroke-opacity: 1;
  }

  to {
    stroke-opacity: 0;
  }
}

/** The styles below are only for specific tooltips/contents **/

.map-gd-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;

  img {
    width: 100%;
    height: auto;
  }
}
