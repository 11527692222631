$base-list-spacing: 1rem;
$base-list-sizing: 1rem;
$base-list-font: 1rem;

.mat-list.mat-list-base {
  font-size: $base-list-font;
  padding-top: 0;

  .mat-list-item,
  .mat-list-option,
  .mat-list-item[class*="-line"] {
    font-size: inherit;
    height: unset;

    .mat-list-item-content {
      padding: 0.75 * $base-list-spacing 0;
    }

    .mat-line {
      font-size: $base-list-font;
    }
  }
}
