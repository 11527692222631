mat-datepicker-content {
  &.mat-datepicker-content {
    border-radius: 2px;
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.3);
  }

  mat-calendar {
    &.mat-calendar {
      font-family: var(--ene-font-family-primary);
      width: 22.5rem !important;
      height: auto !important;
    }

    mat-calendar-header {
      .mat-calendar-header {
        padding: 0 0.5em;

        .mat-calendar-controls {
          margin: 0.5em 0;

          .mat-button {
            font-weight: 600;
            @include ene-font-default();
            color: var(--ene-kpis-color);
            font-family: var(--ene-font-family-primary);

            .mat-calendar-arrow {
              border-top-color: var(--ene-color-NNO);
              margin: 0 0 0 1em;
            }
          }

          .mat-icon-button {
            color: var(--ene-color-NNO);
            &:hover {
              color: var(--ene-datepicker-controls-active);
            }

            &[disabled] {
              color: var(--ene-color-NO);
            }
          }
        }
      }
    }
    .mat-calendar-content {
      padding: 0 1em 1em 1em;
      @include ene-font-default();

      .mat-calendar-table-header {
        .mat-calendar-table-header-divider::after {
          left: -1em;
          right: -1em;
          background-color: var(--ene-color-O);
        }

        th {
          padding: 0 0 1rem 0;
          &::after {
            @include ene-font-default();
          }
        }
      }

      mat-year-view {
        td.mat-calendar-body-label {
          display: none;
        }
      }
      mat-month-view {
        .mat-calendar-table-header {
          th {
            @include ene-font-small();
            font-weight: 600;
            color: var(--ene-kpis-color);
          }
        }
        td.mat-calendar-body-label {
          opacity: 1;
        }
      }
      mat-multi-year-view,
      mat-year-view,
      mat-month-view {
        .mat-calendar-body {
          @include ene-font-default();

          td.mat-calendar-body-cell {
            &.mat-calendar-body-disabled {
              .mat-calendar-body-cell-content {
                color: var(--ene-color-NO);
              }
            }
            .mat-calendar-body-cell-content {
              border-radius: 0;
              border: none;
              color: var(--ene-kpis-color);

              &.mat-calendar-body-selected {
                color: var(--ene-color-SO);
                font-weight: 600;
                background-color: var(--ene-color-S);
              }
            }
          }
        }
      }
    }
  }
}
