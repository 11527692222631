@use "@angular/material" as mat;

div.mat-tooltip {
  background-color: var(--ene-color-grey-800);
  color: var(--ene-color-white);
  max-width: max-content;

  @extend .body-1;

  &.light-tooltip {
    background-color: var(--ene-color-grey-50);
    color: var(--ene-color-grey-700);
    font-size: $default-sizing * $small-scale;

    @include mat.elevation(3);
  }

  &.dark-tooltip {
    max-width: 200px;

    @include mat.elevation(3);
  }

  &.last-reading-tooltip {
    background-color: var(--ene-color-primary-600);
    color: var(--ene-color-white);
    font-size: $default-sizing * $small-scale;

    @include mat.elevation(3);
  }

  &.table-tooltip {
    background-color: var(--ene-color-grey-50);
    color: var(--ene-color-grey-700);
    font-size: $default-sizing * $small-scale;

    @include mat.elevation(3);
  }

  &.warning-tooltip {
    max-width: 300px;
  }

  &.info-tooltip {
    background-color: var(--ene-color-emerald-300);
    color: var(--ene-color-white);
    font-size: $default-sizing * $small-scale;
  }

  &.launchpad-card-tooltip {
    background-color: var(--ene-color-primary-700);
    max-width: 190px;
  }

  &.ene-sidenav-tooltip {
    word-break: normal;
    max-width: 300px;

    @include mat.elevation(3);
  }
}
