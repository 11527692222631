.mat-badge-content {
  background-color: var(--ene-color-emerald-300);
}

.contentful-overlay {
  .mat-badge-content {
    right: 0;
    width: max-content;
    border-radius: 2px;
  }

  .mat-badge-medium,
  .mat-badge-small {
    .mat-badge-content {
      width: max-content;
    }
  }
}
