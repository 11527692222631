.launchpad-backdrop {
  background: rgba(6, 22, 113, 0.6);
}

.launchpad-wrapper {
  --tile-height: 290px;
  --tile-padding: 8px;
  --tile-title-font-size: 24px;
  --tile-description-font-size: 18px;

  --tile-top-margin-bottom: 48px;

  --tile-icon-size: 48px;
  --tile-logo-size: 34px;

  --marketplace-banner-teaser-font-size: 24px;

  --tiles-grid-template-columns: 1fr 1fr 1fr 1fr;

  --launchpad-banner-height: 150px;

  --launchpad-gap: 24px;
  --launchpad-width: 1350px;

  @media (width <= 600px) {
    --tile-height: 150px;
    --marketplace-banner-teaser-font-size: 18px;
  }

  @media (width <= 630px) {
    --launchpad-width: 100%;
    --tiles-grid-template-columns: 1fr;
  }

  @media (630px < width <= 885px) {
    --launchpad-width: 100%;
    --tiles-grid-template-columns: 1fr 1fr;
  }

  @media (885px < width <= 1170px) {
    --launchpad-width: 946px;
    --tiles-grid-template-columns: 1fr 1fr 1fr;
  }

  @media (1170px < width <= 1440px) {
    --launchpad-width: 1150px;
    --tiles-grid-template-columns: 1fr 1fr 1fr;
  }
}
