.mat-form-field.custom {
  border: 1px solid var(--ene-color-grey-300);
  box-sizing: border-box;
  border-radius: 2px;
  height: 3rem;

  &.mat-focused:not(.mat-form-field-invalid) {
    border-color: var(--ene-color-secondary-500);

    .mat-form-field-label {
      color: var(--ene-color-secondary-600);
    }
  }

  &.mat-form-field-disabled {
    opacity: 0.6;
  }

  .mat-input-element {
    color: var(--ene-color-grey-700);
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mat-form-field-infix {
    padding: 0.4375rem 1rem;
  }

  .mat-form-field-appearance-legacy {
    width: auto;
  }

  .mat-form-field-label {
    width: auto !important;
    background-color: var(--ene-color-white);
    color: var(--ene-color-grey-600);
    margin-left: 5px;
    padding: 0 5px;
  }
}

mat-form-field {
  width: 100%;

  &.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-label {
      color: var(--ene-color-secondary-500);
    }
  }

  input:focus {
    outline: none;
  }

  &.input {
    background-color: var(--ene-color-grey-50);
    margin-bottom: 0.5rem;
    width: 100%;

    &.mat-form-field {
      &.mat-form-field-appearance-legacy {
        .mat-form-field-infix {
          padding: 0.5rem 0.5rem 0.2rem 0.5rem;
        }

        &.mat-form-field-can-float {
          &.mat-form-field-should-float .mat-form-field-label,
          .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
            transform: translate3d(0, -1rem, 0.001px) scale(0.75) perspective(100px);
          }
        }
      }

      &-disabled .mat-form-field-underline {
        background: none !important;
      }
    }
  }

  .mat-form-field-suffix .mat-icon {
    color: var(--ene-color-grey-700);
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
}

.mat-form-field {
  &.backgrounded {
    background: var(--ene-color-grey-50);
    margin-bottom: 4px;

    .mat-select-value-text,
    .mat-input-element {
      margin-left: 0.3rem;
    }

    .mat-form-field-label {
      margin-top: 0.3rem;
    }
  }

  &.grouped {
    margin-top: 14px;
  }
}
