.footer.ene-footer {
  .expanded {
    .sub-item {
      margin-left: 16px;
    }
  }

  .expand-option {
    .mat-icon {
      font-size: 0.75rem;
      margin-right: 0.5rem;
      color: var(--ene-color-grey-200);

      &:not(.expanded) {
        transform: rotate(180deg);
      }
    }
  }
}
