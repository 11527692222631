$base-button-spacing: 1rem;
$base-button-sizing: 1rem;
$base-button-font: 1rem;
$base-button-border-radius: 2px;

$big-button-sizing: $base-button-sizing * 2;
$big-button-font: $base-button-font * 1.5;

$default-button-sizing: $base-button-sizing * 2;
$default-button-font: $base-button-font * 1;

$small-button-sizing: $base-button-sizing * 1.8;
$small-button-font: $base-button-font * 0.8;

@mixin button-base($font-size, $height, $width: false) {
  // test case with font
  font-size: $font-size;
  line-height: 1;
  height: $height;

  @if $width {
    width: $width;
  } @else {
    min-width: $height;
    padding: 0 0.5rem;
  }

  i {
    line-height: inherit;
  }

  svg {
    width: $width;
    height: $height;
  }
}

@mixin button-typography-base($font-size: 1rem, $font-weight: 400) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: 1.2;
  text-transform: uppercase;
}

@mixin button-theme($theme: false) {
  @if $theme == secondary {
    background: var(--ene-gradient-secondary-1);
    color: var(--ene-color-white);
  } @else if $theme == dark {
    background: var(--ene-color-grey-600);
    color: var(--ene-color-white);
  } @else {
    background: var(--ene-color-grey-50);
    color: var(--ene-color-secondary-500);
  }

  &:disabled {
    color: currentColor;
    opacity: 0.15;
  }
}

button,
a {
  &.mat-button,
  &.mat-icon-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    border-radius: $base-button-border-radius;
  }

  &.mat-button,
  &.mat-stroked-button {
    &.small {
      @include button-base($small-button-font, $small-button-sizing);

      * + .ene-icon {
        margin-left: 0.5rem;
      }
    }

    &.big {
      @include button-base($big-button-font, $big-button-sizing);
    }
  }

  &.mat-icon-button {
    &:not(.small) {
      @include button-base($default-button-font, $default-button-sizing, $default-button-sizing);
    }

    &.small {
      @include button-base($small-button-font, $small-button-sizing, $small-button-sizing);
    }
  }

  // for default button theming activated just for small version
  &.mat-button.small,
  &.mat-icon-button.small {
    &:not(.secondary):not(.dark) {
      @include button-theme();
    }

    &.secondary {
      @include button-theme(secondary);
    }

    &.dark {
      @include button-theme(dark);
    }
  }

  &.mat-stroked-button {
    &:not([disabled]) {
      border-color: currentColor;
    }

    &.mat-accent .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  &.mat-flat-button {
    &.small {
      line-height: 1.3;
      padding: 0.25rem 0.5rem;
    }

    &.big {
      font-weight: 600;
      text-transform: uppercase;
      padding: 0 1rem;
    }

    .ene-icon:first-child:not(:only-child) {
      margin-right: 0.5rem;
    }
  }

  &.mat-stroked-button,
  &.mat-flat-button {
    .mat-icon {
      width: 1em;
      height: 1em;
      font-size: 1em;
      color: inherit;
    }
  }

  &.mat-flat-button.mat-accent,
  &.mat-raised-button.mat-accent,
  &.mat-fab.mat-accent,
  &.mat-mini-fab.mat-accent {
    &:not([disabled]) {
      background: var(--ene-gradient-secondary-1);
    }
  }
}

.mat-button-base {
  &.button-xl {
    @include button-typography-base(1rem, 500);
  }

  &.button-lg {
    @include button-typography-base(0.875rem, 500);
  }

  &.button-md {
    @include button-typography-base(0.75rem, 500);
  }

  &.button-sm {
    @include button-typography-base(0.625rem, 500);
  }
}
