.ene-chart {
  &.resizable {
    // setting the chart's containers to 100%, so the internal ResizeObserver takes
    // effect when the parent element of the chart changes its size.
    height: 100%;

    .ene-chart-container {
      height: 100%;
    }
  }

  &.scrollable {
    position: relative;
  }
}

.highcharts-tooltip-container svg {
  overflow: visible;
}

.energy-monitor-tooltip {
  .tooltip {
    position: relative;
    font-family: var(--ene-font-family-primary);

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--ene-color-grey-500);
      border-bottom: 1px solid var(--ene-color-grey-100);
      padding-bottom: 0.2rem;
      margin-bottom: 0.2rem;

      &-title {
        font-weight: bold;
        color: var(--ene-color-grey-800);
      }

      &-subtitle {
        color: var(--ene-color-grey-800);
      }

      &-year {
        font-weight: 600;
        color: var(--ene-color-grey-800);
        flex: 1;
      }

      &-value {
        min-width: 5rem;
        &:not(:last-child) {
          padding-right: 0.5rem;
        }
      }

      &-value {
        min-width: 5rem;
        text-align: right;
      }

      &-next-year {
        min-width: 3rem;
        text-align: right;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--ene-color-grey-800);

      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }

      &--footer {
        font-weight: 600;
      }

      &-name,
      &-value,
      &-percentage {
        &:not(:last-child) {
          padding-right: 0.5rem;
        }
      }

      &-value,
      &-percentage {
        text-align: right;
      }

      &-name {
        text-align: left;
        flex: 1;
      }

      &-value {
        min-width: 5rem;
      }

      &-percentage {
        min-width: 3rem;
      }
    }
    &__monthly-data {
      color: var(--ene-color-primary-600);
      background: var(--ene-color-primary-50);
      font-size: 14px;
      padding: 4px;
    }
    &__monthly-data-wrapper {
      width: fit-content;
      white-space: normal;
    }
  }
}

.benchmarking-tooltip {
  width: fit-content;
  line-height: 1.5;
  font-family: var(--ene-font-family-primary);

  .tooltip__body {
    display: table;
  }
  .tendency-icon {
    font-size: larger;
  }
  .bold {
    font-weight: bold;
    color: var(--ene-color-grey-800);
  }
  .grey-800 {
    color: var(--ene-color-grey-800);
  }
  .underlined {
    border-bottom: 1px solid var(--ene-color-grey-100);
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .right-aligned {
    text-align: right;
  }

  .row {
    display: table-row;
  }
  .cell {
    display: table-cell;
  }

  .tooltip {
    &__row {
      width: fit-content;
    }
  }
}

.outage-monitoring-dashboard-chart {
  &--active {
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--ene-color-error-300);
  }

  &--resolved {
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--ene-color-dashboard-resolved);
  }
}

.chart-tooltip-grid-compare {
  &__table {
    font-size: 12px;
    text-align: right;
  }

  &__header-raw {
    border-bottom: 1px solid var(--ene-color-grey-100);
  }

  &__header-cell,
  &__raw-cell {
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
  }

  &__header-cell {
    color: var(--ene-color-grey-500);

    &.name {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 700;
      color: var(--ene-color-grey-800);
    }
  }

  &__raw-cell {
    color: var(--ene-color-grey-800);

    &.name,
    &.total {
      text-align: left;
    }
  }

  &__raw--bold {
    font-weight: 700;
  }

  &__raw--grey {
    td:nth-of-type(1),
    td:nth-of-type(2) {
      font-weight: 600;
    }

    td:nth-of-type(2) {
      text-align: left;
    }
  }

  &__raw--left {
    td:nth-of-type(1),
    td:nth-of-type(2) {
      text-align: left;
    }

    div {
      padding-bottom: 16px;
    }
  }

  .info-card {
    text-align: center;
    font-size: 12px;
    line-height: 1.2;
    white-space: normal;
    background-color: var(--ene-color-primary-50);
    color: var(--ene-color-primary-600);
    border-radius: 2px;
    padding: 4px;
    margin-top: 24px;
  }

  .tag {
    color: var(--ene-color-white);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 2px;
    padding: 2px 2px 0 2px;
    margin-left: 8px;
    line-height: 1;

    &--certified {
      background-color: var(--ene-color-primary-600);
    }

    &--not-certified {
      background-color: var(--ene-color-grey-400);
    }
  }
}
