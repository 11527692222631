.mat-select {
  &.custom-arrow {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    background: var(--ene-color-grey-50);
    padding: 0.5rem 0.7rem 0.5rem;

    .mat-select-arrow-wrapper {
      vertical-align: initial;

      .mat-select-arrow {
        color: var(--ene-color-grey-200);
        transform: rotate(45deg);
        border: none;
        border-bottom: 2px solid var(--ene-color-NNO);
        border-right: 2px solid var(--ene-color-NNO);
        width: 7px;
        height: 7px;
      }
    }

    .mat-select-value {
      text-align: left;
    }
  }

  &.grey {
    background-color: var(--ene-color-grey-500);

    .mat-select-value-text {
      color: var(--ene-color-white);
    }

    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        margin: 0;
        color: var(--ene-color-white);
      }
    }
  }

  &.black {
    background-color: var(--ene-color-grey-700);

    .mat-select-value-text {
      color: var(--ene-color-white);
    }

    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        margin: 0;
        color: var(--ene-color-white);
      }
    }
  }

  &.border-bottom {
    border-bottom: 1px solid var(--ene-color-grey-500);
  }
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--ene-color-grey-700);
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--ene-color-secondary-500);
}
