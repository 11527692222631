#zl-container .zl-style-center {
  background: rgba(0, 0, 0, 0.32);
}

#zl-container .zl-style-center .zl-dialog {
  border-radius: 4px;
  overflow: hidden;
}

#zl-container .zl-pages .zl-page {
  padding: 0;
}

#zl-container .zl-overlay .zl-line {
  display: none;
}

#zl-container .zl-overlay .zl-line+div {
  font-family: 'energycity' !important;
}

#zl-container .zl-btn-nav {
  position: relative;
  display: flex;
  align-items: center;
  background: #f6f6f6;
  border-bottom: 1px solid var(--ene-color-grey-100);
  height: 72px;
  width: 100%;
}

.zl-btn-nav::after {
  content: "Ihre Meinung ist gefragt!";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0 0 10px var(--ene-color-primary-400);
  color: var(--ene-color-primary-600);
}

#zl-container .zl-prev,
#zl-container .zl-close {
  background-size: 20px 20px;
}

#zl-container .zl-pages .zl-close {
  right: 24px;
  top: unset;
}

#zl-container .zl-pages .zl-prev,
#zl-container .zl-pages .zl-expand {
  left: 24px;
  top: unset;
}

#zl-container .zl-pages .zl-page-content {
  padding: 16px 24px;
}

#zl-container .zl-heading {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--ene-color-grey-700);
  line-height: 1.2;
}

#zl-container .zl-input {
  font-family: 'energycity';
}

#zl-container .zl-input:focus {
  border-color: var(--ene-color-secondary-500);
}

#zl-container .zl-btn {
  font-family: 'energycity';
  text-transform: uppercase;
  font-weight: 400;
  background: var(--ene-gradient-secondary-2) !important;
}

#zl-container .zl-style-center .zl-answer-page .zl-buttons {
  padding: 20px 0 0 0;
}

#zl-container .zl-btn:hover {
  cursor: pointer;
}

#zl-container .zl-footer {
  display: none;
}