@import "./components/badge.scss";
@import "./components/button.scss";
@import "./components/dialog.scss";
@import "./components/divider.scss";
@import "./components/expansion.scss";
@import "./components/list.scss";
@import "./components/overlay.scss";
@import "./components/mat-icon.scss";
@import "./components/progress-spinner.scss";
@import "./components/select.scss";
@import "./components/snack-bar.scss";
@import "./components/tooltip.scss";
@import "./components/table.scss";
@import "./components/form-field.scss";
@import "./components/datepicker.scss";
@import "./components/checkbox.scss";
@import "./components/slide-toggle.scss";
@import "./components/mat-checkbox.scss";

$global-z-index: 10000;

.cdk-overlay-container {
  z-index: $global-z-index;
}

#main-overlay-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $global-z-index;
  height: 100%;
  width: 100%;

  &:empty {
    display: none;
  }
}

.dynamic-overlay-backdrop {
  background-color: #ffffffa0;
}

mat-vertical-stepper {
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    font-family: var(--ene-font-family-primary);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .mat-input-element {
    color: var(--ene-color-grey-700);
  }

  .mat-step-icon-content {
    top: 54% !important;
  }

  .mat-vertical-stepper-header {
    padding: 1.2rem 0 0.8rem;
  }

  .mat-vertical-content-container {
    margin-left: 0.8rem;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    font-family: var(--ene-font-family-primary);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }

  .mat-step-header {
    background: none !important;
    font-family: var(--ene-font-family-primary);
  }

  .mat-step-header .mat-step-label {
    font-weight: 600;
    color: var(--ene-color-SSW);
  }

  .mat-step-header .mat-step-icon {
    border: 2px solid var(--ene-color-SSW);
    font-weight: 600;
    background-color: #fff;
    color: var(--ene-color-SSW);

    &-selected {
      color: #fff;
      background-color: var(--ene-color-SSW);
      border-color: transparent;
    }

    &-state-error {
      color: var(--ene-color-error-100);
      border-color: var(--ene-color-error-100);
    }
  }

  .mat-step-header .mat-step-label.mat-step-label-error {
    color: var(--ene-color-error-100);
  }

  .mat-step-header .mat-step-icon-not-touched {
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mat-stepper-vertical-line::before {
    display: none;
  }
}

.mat-form-field.mat-focused:not(.mat-form-field-invalid) .mat-form-field-ripple {
  background: var(--ene-color-S);
}

.mat-form-field-appearance-legacy {
  div.mat-form-field-wrapper {
    padding-bottom: 0;
  }

  div.mat-form-field-underline {
    position: initial;
  }
}

.mat-form-field-label {
  padding-left: 0.5em;
}

.ene-sidepanel .ene-sidepanel-container {
  height: unset;
}

.launchpad {
  .launchpad__left-section {
    .ene-tabs.folder.horizontal > .tabs__tab-bar:not(.disabled) > .tabs__tab.active {
      background-color: transparent !important;
    }

    .tabs__tab-bar {
      position: relative;

      &:after {
        content: " ";
        background-color: var(--ene-color-O);
        opacity: 0.3;
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }

    .tabs__tab {
      margin: 0 !important;
      padding-top: 1em !important;
      padding-bottom: 0 !important;
      color: var(--ene-color-SO) !important;
      background-color: transparent !important;
      font-size: 0.85em !important;

      &.active {
        border-bottom: solid 2px var(--ene-color-SO);

        span {
          color: var(--ene-color-SO) !important;
        }
      }

      span {
        padding-bottom: 0.75em;
      }
    }

    .tabs__panel {
      padding: 0;
      background-color: transparent;
    }

    .mat-checkbox-checkmark-path {
      stroke: var(--ene-surface-sidebar-background) !important;
    }

    .mat-checkbox-background {
      background-color: transparent;
    }
  }
}

.cdk-overlay-pane {
  &.disable-click {
    pointer-events: none !important;
  }

  .mat-menu-panel {
    max-width: 100%;
    min-height: 0;
    margin: 0.5rem;

    .mat-menu-content:not(:empty) {
      padding: 0;
    }

    .mat-menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $default-sizing;
      line-height: unset;

      &[disabled].menu-item-header {
        .ene-icon {
          @include get-icon($large-scale, "--ene-color-S");
        }

        .menu-item-label {
          color: var(--ene-color-N);
          font-size: $default-sizing * $big-scale;
          font-weight: 600;
        }
      }

      .menu-item-label {
        flex: 1;
      }

      .ene-icon {
        margin-right: $default-spacing;

        &:not(.item-header) {
          @include get-icon($big-scale, "--ene-color-S");
        }
      }
    }

    .circle-container {
      display: block;
      box-sizing: border-box;
      text-align: center;
      line-height: $default-sizing * 2;
      border: 2px solid var(--ene-color-secondary-400);
      border-radius: 50%;
      height: $default-sizing * 2;
      width: $default-sizing * 2;
      margin-right: $default-spacing;

      &:not(.active) {
        color: var(--ene-color-secondary-400);
      }

      &.active {
        background-color: var(--ene-color-secondary-400);
        color: var(--ene-color-white);
      }

      .circle-value {
        font-size: $default-sizing * 1.5;
      }
    }
  }
}

.mat-sort-header-arrow {
  color: var(--ene-color-black);
}

.dialog-backdrop {
  background: #ffffffa0;
}

.mat-radio-button {
  margin: 0.75rem 0 0;

  &.small .mat-radio-label {
    .mat-radio-container {
      width: 1rem;
      height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .mat-radio-outer-circle {
        left: initial;
        top: initial;
        width: 1rem;
        height: 1rem;
        border-width: 1px;
      }

      .mat-radio-inner-circle {
        left: initial;
        top: initial;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.radio-group-label {
  &.error {
    color: var(--ene-color-error-100);
  }
  color: var(--ene-color-secondary-500);
}

.tooltip-right {
  z-index: 9000 !important;

  div {
    padding: 0.3rem;
  }

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%) rotate(45deg);
    background-color: var(--ene-tooltip-background-color-dark);
    width: 10px;
    height: 10px;
  }
}
